// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

// Eggs
export const getAllEggsRequest = createAction(
  'GET_ALL_EGGS_REQUEST',
);
export const getAllEggsSuccess = createAction(
  'GET_ALL_EGGS_SUCCESS',
);
export const getAllEggsFailure = createAction(
  'GET_ALL_EGGS_FAILURE',
);
export const createEggsRequest = createAction(
  'CREATE_EGGS_REQUEST',
);
export const createEggsSuccess = createAction(
  'CREATE_EGGS_SUCCESS',
);
export const createEggsFailure = createAction(
  'CREATE_EGGS_FAILURE',
);
export const resetCreateEggsState = createAction(
  'RESET_CREATE_EGGS_STATE',
);
export const updateEggsRequest = createAction(
  'UPDATE_EGGS_REQUEST',
);
export const updateEggsSuccess = createAction(
  'UPDATE_EGGS_SUCCESS',
);
export const updateEggsFailure = createAction(
  'UPDATE_EGGS_FAILURE',
);
export const resetUpdateEggsState = createAction(
  'RESET_UPDATE_EGGS_STATE',
);
export const cancelEggsRequest = createAction(
  'CANCEL_EGGS_REQUEST',
);
export const cancelEggsSuccess = createAction(
  'CANCEL_EGGS_SUCCESS',
);
export const cancelEggsFailure = createAction(
  'CANCEL_EGGS_FAILURE',
);
export const resetCancelEggsState = createAction(
  'RESET_CANCEL_EGGS_STATE',
);
export const tankEggsRequest = createAction(
  'TANK_EGGS_REQUEST',
);
export const tankEggsSuccess = createAction(
  'TANK_EGGS_SUCCESS',
);
export const tankEggsFailure = createAction(
  'TANK_EGGS_FAILURE',
);
export const resetTankEggsState = createAction(
  'RESET_TANK_EGGS_STATE',
);
export const processEggsRequest = createAction(
  'PROCESS_EGGS_REQUEST',
);
export const processEggsSuccess = createAction(
  'PROCESS_EGGS_SUCCESS',
);
export const processEggsFailure = createAction(
  'PROCESS_EGGS_FAILURE',
);
export const resetProcessEggsState = createAction(
  'RESET_PROCESS_EGGS_STATE',
);
// end region
// Egg suppliers
export const getAllEggSuppliersRequest = createAction(
  'GET_ALL_EGG_SUPPLIERS_REQUEST',
);
export const getAllEggSuppliersSuccess = createAction(
  'GET_ALL_EGG_SUPPLIERS_SUCCESS',
);
export const getAllEggSuppliersFailure = createAction(
  'GET_ALL_EGG_SUPPLIERS_FAILURE',
);
export const createEggSuppliersRequest = createAction(
  'CREATE_EGG_SUPPLIERS_REQUEST',
);
export const createEggSuppliersSuccess = createAction(
  'CREATE_EGG_SUPPLIERS_SUCCESS',
);
export const createEggSuppliersFailure = createAction(
  'CREATE_EGG_SUPPLIERS_FAILURE',
);
export const resetCreateEggSuppliersState = createAction(
  'RESET_CREATE_EGG_SUPPLIERS_STATE',
);
export const updateEggSuppliersRequest = createAction(
  'UPDATE_EGG_SUPPLIERS_REQUEST',
);
export const updateEggSuppliersSuccess = createAction(
  'UPDATE_EGG_SUPPLIERS_SUCCESS',
);
export const updateEggSuppliersFailure = createAction(
  'UPDATE_EGG_SUPPLIERS_FAILURE',
);
export const resetUpdateEggSuppliersState = createAction(
  'RESET_UPDATE_EGG_SUPPLIERS_STATE',
);
export const createEggSuppliersCodeRequest = createAction(
  'CREATE_EGG_SUPPLIERS_CODE_REQUEST',
);
export const createEggSuppliersCodeSuccess = createAction(
  'CREATE_EGG_SUPPLIERS_CODE_SUCCESS',
);
export const createEggSuppliersCodeFailure = createAction(
  'CREATE_EGG_SUPPLIERS_CODE_FAILURE',
);
export const resetCreateEggSuppliersCodeState = createAction(
  'RESET_CREATE_EGG_SUPPLIERS_CODE_STATE',
);
// end region
// egg tanks
export const getAllEggTanksRequest = createAction(
  'GET_ALL_EGG_TANKS_REQUEST',
);
export const getAllEggTanksSuccess = createAction(
  'GET_ALL_EGG_TANKS_SUCCESS',
);
export const getAllEggTanksFailure = createAction(
  'GET_ALL_EGG_TANKS_FAILURE',
);

export const createEggTanksRequest = createAction(
  'CREATE_EGG_TANKS_REQUEST',
);
export const createEggTanksSuccess = createAction(
  'CREATE_EGG_TANKS_SUCCESS',
);
export const createEggTanksFailure = createAction(
  'CREATE_EGG_TANKS_FAILURE',
);
export const resetCreateEggTanksState = createAction(
  'RESET_CREATE_EGG_TANKS_STATE',
);

export const updateEggTanksRequest = createAction(
  'UPDATE_EGG_TANKS_REQUEST',
);
export const updateEggTanksSuccess = createAction(
  'UPDATE_EGG_TANKS_SUCCESS',
);
export const updateEggTanksFailure = createAction(
  'UPDATE_EGG_TANKS_FAILURE',
);
export const resetUpdateEggTanksState = createAction(
  'RESET_UPDATE_EGG_TANKS_STATE',
);
// end region
