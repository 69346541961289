/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllreports({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/reports?${payload}`)
        : () => axiosMicro.get('/reports', { params: payload }),
    );
    yield put(Actions.getAllreportsSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllreportsFailure(messages));
    }
  }
}

function* createReport({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/reports', payload));
    yield put(Actions.createReportSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createReportFailure(messages));
    }
  }
}

function* updateReport({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/reports/${payload.id}`, payload.body));
    yield put(Actions.updateReportSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updateReportFailure(messages));
    }
  }
}

function* deleteReport({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/reports/${payload}`));
    yield put(Actions.deleteReportSuccess(payload));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.deleteReportFailure(messages));
    }
  }
}

function* getReportWarehouseDetail({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/reports/warehouse-detail', {
      params: payload,
    }));
    yield put(Actions.getReportWarehouseDetailSuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getReportWarehouseDetailFailure(messages));
    }
  }
}

function* getReportWarehouseGeneral({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/reports/warehouse-total', {
      params: payload,
    }));
    yield put(Actions.getReportWarehouseGeneralSuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getReportWarehouseGeneralFailure(messages));
    }
  }
}
function* exportExcelGetReportWarehouseDetail({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/reports/warehouse-detail', {}, {
      params: payload,
    }));
    yield put(Actions.exportExcelGetReportWarehouseDetailSuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.exportExcelGetReportWarehouseDetailFailure(messages));
    }
  }
}

function* exportReportWarehouseGeneral({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/reports/warehouse-total', {}, {
      params: payload,
    }));
    yield put(Actions.exportReportWarehouseGeneralSuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.exportReportWarehouseGeneralFailure(messages));
    }
  }
}
function* getReportWarehouseProduct({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/reports/warehouse-product', {
      params: payload,
    }));
    yield put(Actions.getReportWarehouseProductSuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getReportWarehouseProductFailure(messages));
    }
  }
}

function* exportExcelGetReportWarehouseProduct({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/reports/warehouse-product', {}, {
      params: payload,
    }));
    yield put(Actions.exportExcelGetReportWarehouseProductSuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.exportExcelGetReportWarehouseProductFailure(messages));
    }
  }
}

function* getListCustomerReport({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/customers?${payload}`)
        : () => axiosMicro.get('/customers', { params: payload }),
    );
    yield put(Actions.getListCustomerReportSuccess(response.data));
  } catch (error) {
    if (error.message?.data) {
      const messages = error.message.data;
      yield put(Actions.getListCustomerReportFailure(messages));
    }
  }
}
function* getListCustomerReportDup({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/customers?${payload}`)
        : () => axiosMicro.get('/customers', { params: payload }),
    );
    yield put(Actions.getListCustomerReportDupSuccess(response.data));
  } catch (error) {
    if (error.message?.data) {
      const messages = error.message.data;
      yield put(Actions.getListCustomerReportDupFailure(messages));
    }
  }
}

function* getReportDetailCustomer({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/reports/warehouse-customer', {
      params: payload,
    }));
    yield put(Actions.getReportCustomerDetailSuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getReportCustomerDetailFailure(messages));
    }
  }
}

function* exportReportCustomerDetail({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/reports/warehouse-customer', {}, {
      params: payload,
    }));
    yield put(Actions.exportReportCustomerDetailSuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.exportReportCustomerDetailFailure(messages));
    }
  }
}

function* getReportCustomerExport({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/reports/export-customer', {
      params: payload,
    }));
    yield put(Actions.getReportCustomerExportSuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getReportCustomerExportFailure(messages));
    }
  }
}

function* exportReportCustomerExport({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/reports/export-customer', {}, {
      params: payload,
    }));
    yield put(Actions.exportReportCustomerExportSuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.exportReportCustomerExportFailure(messages));
    }
  }
}

function* getReportProcessDetail({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/reports/process-detail', {
      params: payload,
    }));
    yield put(Actions.getReportProcessDetailSuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getReportProcessDetailFailure(messages));
    }
  }
}

function* exportReportProcessDetail({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/reports/process-detail', {}, {
      params: payload,
    }));
    yield put(Actions.exportReportProcessDetailSuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.exportReportProcessDetailFailure(messages));
    }
  }
}

function* getReportProcessTotal({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/reports/process-total', {
      params: payload,
    }));
    yield put(Actions.getReportProcessTotalSuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getReportProcessTotalFailure(messages));
    }
  }
}

function* exportReportProcessTotal({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/reports/process-total', {}, {
      params: payload,
    }));
    yield put(Actions.exportReportProcessTotalSuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.exportReportProcessTotalFailure(messages));
    }
  }
}

function* getReportExportDay({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/reports/export-day', {
      params: payload,
    }));
    yield put(Actions.getReportExportDaySuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getReportExportDayFailure(messages));
    }
  }
}

function* exportReportExportDay({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/reports/export-day', {}, {
      params: payload,
    }));
    yield put(Actions.exportReportExportDaySuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.exportReportExportDayFailure(messages));
    }
  }
}

function* saveProcess({ payload }) {
  try {
    const { params, ...data } = payload;
    const response = yield call(() => axiosMicro.post(`/processes/${params}`, data));
    yield put(Actions.saveReportProcessDetailSuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.saveReportProcessDetailFailure(messages));
    }
  }
}

function* getReportExportDate({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/reports/export-date', {
      params: payload,
    }));
    yield put(Actions.getReportExportDateSuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getReportExportDateFailure(messages));
    }
  }
}

function* getReportExportOrderDate({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/reports/export-order-date', {
      params: payload,
    }));
    yield put(Actions.getReportExportOrderDateSuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getReportExportOrderDateFailure(messages));
    }
  }
}

function* exportReportExportDate({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/reports/export-date', {}, {
      params: payload,
    }));
    yield put(Actions.exportReportExportDateSuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.exportReportExportDateFailure(messages));
    }
  }
}

function* exportReportExportOrderDate({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/reports/export-order-date', {}, {
      params: payload,
    }));
    yield put(Actions.exportReportExportOrderDateSuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.exportReportExportOrderDateFailure(messages));
    }
  }
}

function* getReportSellDay({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/reports/export-sell', {
      params: payload,
    }));
    yield put(Actions.getReportSellDaySuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getReportSellDayFailure(messages));
    }
  }
}

function* exportReportSellDay({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/reports/export-sell', {}, {
      params: payload,
    }));
    yield put(Actions.exportReportSellDaySuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.exportReportSellDayFailure(messages));
    }
  }
}

function* getReportCustomerExportWarehouse({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/reports/export-sell', {
      params: payload,
    }));
    yield put(Actions.getReportCustomerExportWarehouseSuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getReportCustomerExportWarehouseFailure(messages));
    }
  }
}

function* exportReportCustomerExportWarehouse({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/reports/export-sell', {}, {
      params: payload,
    }));
    yield put(Actions.exportReportCustomerExportWarehouseSuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.exportReportCustomerExportWarehouseFailure(messages));
    }
  }
}

function* getReportExportPlan({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/reports/export-plan', {
      params: payload,
    }));
    yield put(Actions.getReportExportPlanSuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getReportExportPlanFailure(messages));
    }
  }
}

function* exportReportExportPlan({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/reports/export-plan', {}, {
      params: payload,
    }));
    yield put(Actions.exportReportExportPlanSuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.exportReportExportPlanFailure(messages));
    }
  }
}

function* getReportDebtMoney({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/reports/debt-money', {
      params: payload,
    }));
    yield put(Actions.getReportDebtMoneySuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getReportDebtMoneyFailure(messages));
    }
  }
}

function* exportReportDebtMoney({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/reports/debt-money', {}, {
      params: payload,
    }));
    yield put(Actions.exportReportDebtMoneySuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.exportReportDebtMoneyFailure(messages));
    }
  }
}

function* getReportOrderBooking({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/reports/order-booking', {
      params: payload,
    }));
    yield put(Actions.getReportOrderBookingSuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getReportOrderBookingFailure(messages));
    }
  }
}

function* exportReportOrderBooking({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/reports/order-booking', {}, {
      params: payload,
    }));
    yield put(Actions.exportReportOrderBookingSuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.exportReportOrderBookingFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.createReportRequest, createReport);
  yield takeLatest(Actions.updateReportRequest, updateReport);
  yield takeLatest(Actions.deleteReportRequest, deleteReport);
  yield takeLatest(Actions.getAllreportsRequest, getAllreports);
  yield takeLatest(Actions.getReportSellDayRequest, getReportSellDay);
  yield takeLatest(Actions.saveReportProcessDetailRequest, saveProcess);
  yield takeLatest(Actions.getReportExportDayRequest, getReportExportDay);
  yield takeLatest(Actions.getReportExportDateRequest, getReportExportDate);
  yield takeLatest(Actions.exportReportSellDayRequest, exportReportSellDay);
  yield takeLatest(Actions.getReportProcessTotalRequest, getReportProcessTotal);
  yield takeLatest(Actions.getListCustomerReportRequest, getListCustomerReport);
  yield takeLatest(Actions.exportReportExportDayRequest, exportReportExportDay);
  yield takeLatest(Actions.exportReportExportDateRequest, exportReportExportDate);
  yield takeLatest(Actions.getReportProcessDetailRequest, getReportProcessDetail);
  yield takeLatest(Actions.getReportCustomerExportRequest, getReportCustomerExport);
  yield takeLatest(Actions.getReportCustomerDetailRequest, getReportDetailCustomer);
  yield takeLatest(Actions.getReportWarehouseDetailRequest, getReportWarehouseDetail);
  yield takeLatest(Actions.exportReportProcessTotalRequest, exportReportProcessTotal);
  yield takeLatest(Actions.getListCustomerReportDupRequest, getListCustomerReportDup);
  yield takeLatest(Actions.getReportExportOrderDateRequest, getReportExportOrderDate);
  yield takeLatest(Actions.getReportWarehouseGeneralRequest, getReportWarehouseGeneral);
  yield takeLatest(Actions.getReportWarehouseProductRequest, getReportWarehouseProduct);
  yield takeLatest(Actions.exportReportProcessDetailRequest, exportReportProcessDetail);
  yield takeLatest(Actions.exportReportCustomerDetailRequest, exportReportCustomerDetail);
  yield takeLatest(Actions.exportReportCustomerExportRequest, exportReportCustomerExport);
  yield takeLatest(Actions.exportReportExportOrderDateRequest, exportReportExportOrderDate);
  yield takeLatest(Actions.exportReportWarehouseGeneralRequest, exportReportWarehouseGeneral);
  yield takeLatest(Actions.getReportCustomerExportWarehouseRequest, getReportCustomerExportWarehouse);
  yield takeLatest(Actions.exportReportCustomerExportWarehouseRequest, exportReportCustomerExportWarehouse);
  yield takeLatest(Actions.exportExcelGetReportWarehouseDetailRequest, exportExcelGetReportWarehouseDetail);
  yield takeLatest(Actions.exportExcelGetReportWarehouseProductRequest, exportExcelGetReportWarehouseProduct);
  yield takeLatest(Actions.getReportExportPlanRequest, getReportExportPlan);
  yield takeLatest(Actions.exportReportExportPlanRequest, exportReportExportPlan);
  yield takeLatest(Actions.getReportDebtMoneyRequest, getReportDebtMoney);
  yield takeLatest(Actions.exportReportDebtMoneyRequest, exportReportDebtMoney);
  yield takeLatest(Actions.getReportOrderBookingRequest, getReportOrderBooking);
  yield takeLatest(Actions.exportReportOrderBookingRequest, exportReportOrderBooking);
}
