/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllEggs({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/eggs', payload));
    yield put(Actions.getAllEggsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllEggsFailure(messages));
    }
  }
}

function* createEggs({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/eggs', payload));
    yield put(Actions.createEggsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createEggsFailure(messages));
    }
  }
}

function* updateEggs({ payload }) {
  const { id, ...data } = payload;
  try {
    const response = yield call(() => axiosMicro.patch(`/eggs/${id}`, data));
    yield put(Actions.updateEggsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateEggsFailure(messages));
    }
  }
}

function* cancelEggs({ payload }) {
  try {
    yield call(() => axiosMicro.patch(`/eggs/${payload.id}/cancel`));
    yield put(Actions.cancelEggsSuccess());
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.cancelEggsFailure(messages));
    }
  }
}

function* tankEggs({ payload }) {
  const { id, ...data } = payload;
  try {
    yield call(() => axiosMicro.post(`/eggs/${id}/tank`, data));
    yield put(Actions.tankEggsSuccess());
  } catch (error) {
    const messages = error.response.data;
    yield put(Actions.tankEggsFailure(messages));
  }
}

function* processEggs({ payload }) {
  const { id, ...data } = payload;
  try {
    yield call(() => axiosMicro.post(`/eggs/${id}/process`, data));
    yield put(Actions.processEggsSuccess());
  } catch (error) {
    const messages = error.response.data;
    yield put(Actions.processEggsFailure(messages));
  }
}

function* getAllEggSuppliers({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/egg-suppliers', payload));
    yield put(Actions.getAllEggSuppliersSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllEggSuppliersFailure(messages));
    }
  }
}

function* createEggSuppliers({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/egg-suppliers', payload));
    yield put(Actions.createEggSuppliersSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createEggSuppliersFailure(messages));
    }
  }
}

function* updateEggSuppliers({ payload }) {
  const { id, ...data } = payload;
  try {
    const response = yield call(() => axiosMicro.patch(`/egg-suppliers/${id}`, data));
    yield put(Actions.updateEggSuppliersSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateEggSuppliersFailure(messages));
    }
  }
}

function* createEggSuppliersCode({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/egg-suppliers/code', payload));
    yield put(Actions.createEggSuppliersCodeSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createEggSuppliersCodeFailure(messages));
    }
  }
}

function* getAllEggTanks({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/egg-tanks', payload));
    yield put(Actions.getAllEggTanksSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllEggTanksFailure(messages));
    }
  }
}

function* createEggTanks({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/egg-tanks', payload));
    yield put(Actions.createEggTanksSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createEggTanksFailure(messages));
    }
  }
}

function* updateEggTanks({ payload }) {
  const { id, ...data } = payload;
  try {
    const response = yield call(() => axiosMicro.patch(`/egg-tanks/${id}`, data));
    yield put(Actions.updateEggTanksSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateEggTanksFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllEggsRequest, getAllEggs);
  yield takeLatest(Actions.createEggsRequest, createEggs);
  yield takeLatest(Actions.updateEggsRequest, updateEggs);
  yield takeLatest(Actions.cancelEggsRequest, cancelEggs);
  yield takeLatest(Actions.tankEggsRequest, tankEggs);
  yield takeLatest(Actions.processEggsRequest, processEggs);

  yield takeLatest(Actions.getAllEggSuppliersRequest, getAllEggSuppliers);
  yield takeLatest(Actions.createEggSuppliersRequest, createEggSuppliers);
  yield takeLatest(Actions.updateEggSuppliersRequest, updateEggSuppliers);
  yield takeLatest(Actions.createEggSuppliersCodeRequest, createEggSuppliersCode);

  yield takeLatest(Actions.getAllEggTanksRequest, getAllEggTanks);
  yield takeLatest(Actions.createEggTanksRequest, createEggTanks);
  yield takeLatest(Actions.updateEggTanksRequest, updateEggTanks);
}
